/* 
* @Author: liuzhixiang  
* @Date: 2021-08-07 09:34:51  
 * @Last Modified by: liuzhixiang
 * @Last Modified time: 2021-11-Tu 04:36:59
*/
<template>
  <div class="details">
    <div class="title">{{ details.Title }}</div>
    <div class="time">
      <span>{{ details.CreateTime | dataYMD }}</span>
      <span>{{ details.ReadCount }}人看过</span>
    </div>
    <div class="content" v-html="details.ContentStr"></div>
  </div>
</template>

<script>
import { commonQueryNewsDetail } from "@/api/home";
import noData from "@/components/noData";
export default {
  data () {
    return {
      list: [],
      page: 1,
      pageSize: 5,
      total: 0,
      details: {},
    };
  },
  components: {
    noData,
  },
  created () {
    this.route = this.$route.query;
  },
  mounted () {
    this.init();
  },
  methods: {
    //
    async init () {
      let parm = "?id=" + this.route.id;
      const res = await commonQueryNewsDetail(parm);
      if (res.success === true) {
        this.details = res.response;
      } else {
        this.$message.error(res.msg);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.details {
  padding: 15px;
  .title {
    font-size: 16px;
    font-weight: bold;
    line-height: 22px;
  }
  .time {
    font-size: 14px;
    color: #666666;
    line-height: 20px;
    margin: 10px 0 0;
    padding-bottom: 15px;
    border-bottom: 1px solid #e8e8e8;
    span + span {
      margin-left: 20px;
    }
  }
  .content {
    line-height: 1.8;
    padding: 15px 0;
    font-size: 13px;
    color: #666666;
    /deep/img,
    /deep/video {
      max-width: 100%;
    }
    /deep/video::-webkit-media-controls-fullscreen-button {
      display: none;
    }
  }
}

/deep/video::-webkit-media-controls-fullscreen-button {
  display: none;
}
</style>